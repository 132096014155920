<template>
    <div class="springAwakening2023_main">

        <nav-bar header-title="达信自助卡" backColor="#fff" :show-back="true" :header-back="newAppBack" ref="head"/>
        <div class="headbg">
            <img src="https://img.chaolu.com.cn/ACT/temp-activity/daxin-venue-mall/1.png" class="head-img" alt=""/>
            <img src="https://img.chaolu.com.cn/ACT/temp-activity/daxin-venue-mall/2.png" class="title-head-img" alt=""/>
            <div @click="showRule = true" class="rule-btn row-center-center">规则</div>
        </div>
<!--        <div class="bind-coach row-start-center">-->
<!--            <img :src="$getImgUrl(inviteUserInfo.shareUserHeadImg)" alt="" class="avator flex-none">-->
<!--            <p class="f24 ellipsis">{{ inviteUserInfo.shareTitle }}邀请你参与会员专属福利</p>-->
<!--        </div>-->
        <!--自助卡-->
        <div class="goodsbox col-start-center" style="margin-top: 0">
            <div class="see-title">
                <img src="https://img.chaolu.com.cn/ACT/temp-activity/new-venue-mall/2.png" alt=""/>
            </div>
            <div class="basebg">
                <!-- <div class="coner f34 fw6 row-start-center">{{ key }}元团课月卡</div> -->
                <div class="goods-model" v-for="(citem, index) in zzkList" :key="index">
                    <div class="pub_full">
                        <div class="goods-img"><img :src="citem.picUrl"/></div>
                        <div class="pub_onefull">
                            <div class="goods-name">{{ citem.itemName }}</div>
                            <div class="label-line">
                                <div class="label-model" v-if="citem.discountRate">
                                    <div class="scale">{{ citem.discountRate }}折</div>
                                </div>
<!--                                <div class="label-model" v-if="citem.save">-->
<!--                                    <div class="scale">{{ citem.save }}</div>-->
<!--                                </div>-->
                            </div>
                            <div v-if="citem.describe" class="describe row-center-center">{{citem.describe}}</div>
                            <div class="price-line">
                                <span class="dl">¥</span>
                                <span class="price">{{ citem.salePrice }}</span>
                                <span class="oldprice">¥{{ citem.originalPrice }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="pub_avg line2" :style="citem.save && 'align-items: flex-end'">
                        <div>
                            <div class="invalid-time" v-if="citem.expiryDateDescribe"
                                 v-html="citem.expiryDateDescribe"></div>
                            <div v-if="citem.save" class="invalid-time">{{ citem.save }}</div>
                        </div>

                        <div class="buy" :class="{ gray: citem.itemStatus != 'CAN_BUY' }"
                             @click="openBuyCity(citem, 14)">{{
                                buttonText(citem)
                            }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <van-popup v-model="showRule" position="bottom" safe-area-inset-bottom class="pop-rule">
            <div class="pop-rule-title">
                {{ rules.title }}
                <van-icon name="cross" class="pop-rule-close" color="#fff" @click="showRule = false"/>
            </div>
            <div class="pop-rule-content">
                <div v-for="(item, index) in rules.content" :key="index">{{ item }}</div>
            </div>
        </van-popup>

        <van-popup v-model="showCity" position="bottom" safe-area-inset-bottom class="popcity">
            <div class="buy-title">
                选择购买城市
                <van-icon name="cross" class="buy-close" color="#9AA1A9" @click="showCity = false"/>
            </div>
            <div class="city-list">
                <div v-for="(item, index) in cityList" :key="index" class="city-model"
                     :class="{ 'city-active': cityIndex === index }" @click="cityIndex = index">
                    {{ item.name }}
                    <div class="checkbox"></div>
                </div>
                <div class="next" @click="showSure = cityIndex != undefined ? true : false">下一步</div>
            </div>
        </van-popup>
        <van-popup v-model="showSure" position="center" class="popsure">
            <div class="sure-title">提示</div>
            <div class="sure-content">您当前购买的自助健身卡，仅限<span>广州达信大厦店</span>使用。
            </div>
            <div class="sure-sub" @click="next">我知道了</div>
        </van-popup>

        <van-popup v-model="showCity" position="bottom" safe-area-inset-bottom class="popcity">
            <div class="buy-title">
                选择购买城市
                <van-icon name="cross" class="buy-close" color="#9AA1A9" @click="showCity = false"/>
            </div>
            <div class="city-list">
                <div v-for="(item, index) in cityList" :key="index" class="city-model"
                     :class="{ 'city-active': cityIndex === index }" @click="cityIndex = index">
                    {{ item.name }}
                    <div class="checkbox"></div>
                </div>
                <div class="next" @click="showSure = cityIndex != undefined ? true : false">下一步</div>
            </div>
        </van-popup>

    </div>
</template>
<script>
import {newAppBack, initBack, appPay, appPaySuccess} from '@/lib/appMethod'
import navBar from '@/components/nav-bar/nav-bar'

import {scheduleProtocol, protocol} from '@/views/month-card/const'
import userMixin from '@/mixin/userMixin'
import headerMixin from '@/mixin/headerMixin'
import {isEmpty} from '@/lib/utils'

const activityNo = 'daxinMonthSale';
const webUrl = `${window.location.origin}/#/temp-activity/daxin-venue-mall`
export default {
    components: {
        navBar,
    },
    mixins: [userMixin, headerMixin],
    data() {
        return {
            showShare: false,

            showRule: false,
            showSure: false,

            inviteTeacherId: '', // 教练id
            inviteUserInfo: {}, // 邀请人信息

            activeKey: 3,

            cityList: [
                {name: '福州市'},
                {name: '厦门市'},
                {name: '广州市'}
            ],
            cardTitle: [
                '', '送朋友，一起来运动！', '送伴侣，健身更迷人！', '送亲人，健康添福气！'
            ],

            showCity: false,
            cityIndex: undefined,

            cardMap: [],//  礼品卡
            microCoursePackageMap: [],//  小班课
            equityCardMap: [], // 团课月卡
            zzkList: [], // 自助卡筛选后展示用
            zzkIndex: 0, // 自助卡第几类
            ticketEquityCardMap: [], // 自助卡二重数组
            scheduleCoursePackageMap: {}, // 团课
            perCoursePackageMap: {},// 私教
            stickyHeight: 0,
            rules: {
                title: '活动规则',
                content: [
                    '1、自助健身卡，只用于超鹿达信大厦店自助健身。',
                    '2、使用该卡，除高峰时段（19:00-21:00）的其他时间段，可享受免费自助健身；每30天赠送12小时高峰时段（19:00-21:00）免费自助健身时长，超出12小时部分以5折计算自助健身费用。',
                    '3、自助健身卡不可与优惠券、余额同时使用。',
                    '4、商品购买后不支持退款。商品均设置有效期，请在有效期内尽快使用，逾期失效。',
                    '5、活动最终解释权归超鹿所有。如有疑问可联系客服：400-700-5678。',
                ]
            },
            selectPayItem: null,
        }
    },
    methods: {
        newAppBack,
        isEmpty,
        buttonText(item = {}) {
            const status = item.itemStatus;
            const limit = item.hasBuyLimit;
            const json = {
                'NOT_STARTED': '待开启',
                'EXPIRED': '已结束',
                'SOLD_OUT': '已售罄',
                'HAVE_BUY': '已购买',
                'CAN_BUY': '立即购买',
                'NOT_BUY': '不可购买'
            }
            let text = json[status] || '立即购买';
            if (status == 'CAN_BUY' && limit) {
                text = '限量抢';
            }
            return text;
        },

        next() {
            this.showSure = false;
            this.showCity = false;
            this.buyCard(this.selectPayItem, 14);
        },
        getActivityInfo() {
            this.$axios
                .post(this.baseURLApp + '/userDistribute/bindUser', {
                    ...this.params,
                    saleTeacherId: this.inviteTeacherId,
                })
                .then((res) => {


                });
        },
        getGoodList() {
            const toast = this.$toast.loading({
                duration: 0,
                forbidClick: true,
                loadingType: "spinner",
                message: '加载中...',
            })
            this.$axios.post(`${this.baseURLApp}/anniversary/sale/getItemList`, this.params).then(res => {
                this.zzkList = []
                this.ticketEquityCardMap = []
                const r = res.data;
                for (let i in r.equityCardMap) {
                    this.zzkList.push(...r.equityCardMap[i]);

                }
                toast.clear()
            }).catch(r => {
                toast.clear()
            })
        },
        async buyCard(item, type) {

            if (item.itemStatus !== 'CAN_BUY') {
                this.$toast('很遗憾，您暂不满足购买条件。')
                return
            }
            const res = await this.checkCanBuy(item);
            if (!res) {
                this.getGoodList()
                return
            }

            // 服务端需要 做个转换
            const obj = JSON.parse(JSON.stringify(item))
            obj.itemId = obj.isFlashSale ? obj.itemId.split('-')[0] : obj.itemId

            this.payAction(obj, type)
        },
        payAction(info, type) {
            let url = `/pages/webView/index?webUrl=${webUrl}&userId=1&token=1`
            let payData = {
                subject: info.itemName,
                extendsJson: {
                    appVersion: '2.1.11',
                    seriesName: '超鹿会员商城',
                    inviteUserId: this.inviteId,
                },
                totalAmount: info.salePrice,
                venueId: '31',
                goodsNum: type === 12 ? '1' : undefined,
                isApp: '1',
                type: type, // 礼品卡12  课包10 月卡
                userId: this.params.userId,
                token: this.params.token,
                extendsType: '4',
                mouldId: info.itemId,
                whetherPrivacy: 0,
                originalPriceStr: '',
                title: info.itemName,
            }
            if (this.appTypeStr === 'mini') {
                payData.wxPayType = 'applet'
            }
            appPay(payData, url);
            console.log(payData, url);
            if (this.appTypeStr !== 'mini') {
                appPaySuccess().then(() => {
                    this.paySuccess();
                    this.$toast('支付成功');
                })
            }
        },
        paySuccess() {
            setTimeout(() => {
                this.getGoodList();
                this.getInviteUserInfo();
            }, 500)
        },
        checkCanBuy(info) {
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                loadingType: 'spinner',
            })
            return this.$axios.post(this.baseURLApp + '/anniversary/sale/checkCanBuy', {
                ...this.params,
                itemId: info.itemId
            }).then((r) => {
                this.$toast.clear()
                return r.code
            }).catch(() => {
                this.$toast.clear()
                return 0
            })
        },
        openBuyCity(item) {
            this.selectPayItem = item
            this.showSure = true;
        },
        // 接受好友邀请
        getInviteUserInfo() {
            this.$axios.post(this.baseURLApp + '/userDistribute/bindUser', {
                ...this.params,
                saleTeacherId: this.inviteTeacherId
            }).then((res) => {
                this.inviteUserInfo = res.data
                this.inviteTeacherId = res.data.shareTeacherId
            })
        },
    },
    mounted() {
        setTimeout(() => {
            this.stickyHeight = this.$refs.head.$el.offsetHeight
        }, 1000)
    },
    async created() {
        initBack();
        this.inviteTeacherId = this.$route.query.teacherId || this.$route.query.inviteTeacherId
        await this.$getUserId();
        this.params = {
            activityNo,
            userId: this.userId,
            token: this.token,
            hasWx: true
        }
        if(this.inviteTeacherId){
            this.getInviteUserInfo()
        }
        this.getGoodList()
        appPaySuccess().then(() => {
            this.paySuccess();
        })
    },

}
</script>
<style lang="less" scoped>
.springAwakening2023_main {
    min-height: 100vh;
    background: #F8AC3F;
    padding-bottom: 60px;
    box-sizing: border-box;
    div {
        box-sizing: border-box;
    }
    img {
        vertical-align: top;
        width: 100%;
    }

    .leftbg .van-icon-arrow-left {
        width: 64px;
        height: 64px;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        text-align: center;
        line-height: 64px;
        font-size: 38px !important;
    }

    .share {
        width: 46px;
        height: 46px;
    }

    .headbg {
        position: relative;
        .title-head-img{
            position: relative;
            top: -44px;
        }
    }
    .rule-btn{
        width: 88px;
        height: 56px;
        font-size: 24px;
        color: #FFFFFF;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 28px 0 0 28px;
        position: absolute;
        top: 190px;
        right: 0;
    }
    .bind-coach {
        width: 686px;
        height: 88px;
        background: linear-gradient(88deg, #FDD9A4 0%, rgba(252, 211, 153, 0) 100%, rgba(253, 217, 164, 0) 100%);
        border-radius: 124px;
        margin: 0 auto 24px;
        padding: 0 18px;

        .avator {
            width: 64px;
            height: 64px;
            border-radius: 50%;
            border: 2px solid #fff;
            margin: 0 24px 0 0;
        }
    }

    .goods-title {
        height: 100px;
        margin-top: 40px;
    }

    .tofu {
        padding: 16px 32px 0;
        position: relative;
        background: #FF9926;

        .tm {
            width: 216px;
            height: 72px;
            background: rgba(0,0,0,0.1);
            border-radius: 12px;
            color: #FFFFFF;
            margin-bottom: 16px;

            &.big {
                width: 334px;
            }

            &.active {
                color: #311C07;
                background: linear-gradient(180deg, #FFFFFF 0%, #FFEDB8 100%);
            }
        }


    }

    .goodsbox {
        padding: 0 32px 14px;
        margin-top: 40px;

        .classimg {
            height: 58px;
            width: fit-content;
            padding: 0 68px;
            background-image: url(https://img.chaolu.com.cn/ACT/normal-coach-mall/title-bg-l.png), url(https://img.chaolu.com.cn/ACT/normal-coach-mall/title-bg.png);
            background-size: 68px 58px;
            background-position: left top, right top;
            background-repeat: no-repeat;
            color: #8D3500;
        }

        .seerule {
            width: 160px;
            height: 42px;
            margin: 28px auto 44px;
        }
        .see-title{
            width: 446px;
            height: 40px;
            margin: 0 auto 40px;
        }
        .basebg {
            width: 100%;

            background: rgba(255,255,255,0.2);
            border-radius: 16px;
            position: relative;
            padding: 28px 24px 12px;
            margin-bottom: 42px;
            min-height: 300px;
        }

        .pdt {
            padding-top: 94px;
        }

        .coner {
            word-break: keep-all;
            position: absolute;
            top: -10px;
            left: 0;
            padding: 0 24px;
            width: 312px;
            box-sizing: border-box;
            font-size: 30px;
            height: 72px;
            background: url("https://img.chaolu.com.cn/ACT/temp-activity/new-venue-mall/9.png") no-repeat center center;
            background-size: 100% 100%;

            color: #311C07;
        }

        .goods-model {
            background: #fff;
            border-radius: 12px;
            margin-bottom: 24px;
            padding: 22px;
            height: 292px;

            .goods-img {
                width: 264px;
                height: 160px;
                background-color: rgba(255, 255, 255, 0.7);
                margin-right: 22px;
                border-radius: 10px;
                overflow: hidden;
                flex-shrink: 0;

                img {
                    object-fit: cover;
                }
            }

            .goods-name {
                font-weight: bold;
                color: #242831;
                font-size: 26px;
                padding-top: 2px;
            }
            .describe{
                width: 96px;
                height: 36px;
                border-radius: 4px;
                color: #FFFFFF;
                font-weight: bold;
                font-size: 20px;
                background: #FF6C01;
                margin-bottom: 20px;
            }
            .label-line {
                margin: 14px 0 14px;
                white-space: nowrap;

                .label-model {
                    padding: 0 10px;
                    height: 36px;
                    background: linear-gradient(129deg, #FF8D4D 0%, #FF4E44 100%);
                    border-radius: 6px;
                    color: #fff;
                    font-weight: bold;
                    font-size: 24px;
                    margin-right: 12px;
                    line-height: 1;
                    display: inline-flex;
                    align-items: center;

                    .scale {
                        transform: scale(0.9);
                        transform-origin: 50% 50%;
                    }
                }

                .limit {
                    padding: 0;
                    background: #79AF52;
                }
            }

            .price-line {
                .dl {
                    font-size: 24px;
                    color: #F03C18;
                    font-weight: bold;
                    margin-right: 4px;
                }

                .price {
                    font-size: 48px;
                    color: #F03C18;
                    font-weight: bold;
                    margin-right: 20px;
                    position: relative;
                    top: 2px;
                }

                .oldprice {
                    text-decoration: line-through;
                    color: #6C727A;
                    font-size: 24px;
                }
            }

            .line2 {
                align-items: center;
                margin-top: 14px;

                .invalid-time {
                    color: #3C454E;
                    font-size: 24px;
                    transform: scale(0.9);
                    transform-origin: 0 50%;
                }

                .buy {
                    width: 216px;
                    height: 66px;
                    background: linear-gradient(90deg, #FF8A00 0%, #FF6B00 100%);
                    border-radius: 33px;
                    color: #fff;
                    text-align: center;
                    line-height: 66px;
                    font-size: 30px;
                    font-family: PuHuiTi;
                }

                .gray {
                    color: #6C727A;
                    background: #DDDDDD;
                    box-shadow: none
                }
            }
        }
    }

    .pop-rule {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;

        .pop-rule-title {
            height: 132px;
            font-weight: bold;
            background: linear-gradient(180deg, #FFDBD9 0%, #FCFEFF 100%);
            font-size: 32px;
            text-align: center;
            line-height: 130px;
            position: relative;

            .pop-rule-close {
                position: absolute;
                right: 32px;
                top: 38px;
                width: 44px;
                height: 44px;
                line-height: 44px;
                border-radius: 50%;
                background: rgba(39, 40, 49, 0.2);
                font-size: 28px;
            }
        }

        .pop-rule-content {
            color: #242831;
            font-size: 26px;
            padding: 0 52px;
            max-height: 600px;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            padding-bottom: 60px;
            white-space: pre-line;

            div {
                margin-bottom: 46px;
                line-height: 1.6;
            }
        }
    }


    .popcity {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;

        .buy-title {
            font-weight: bold;
            color: #242831;
            font-size: 32px;
            border-bottom: 1px solid #EEEEEE;
            height: 120px;
            padding: 0 32px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .buy-close {
            font-size: 36px;
        }

        .city-list {
            padding: 8px 32px 32px;

            .city-model {
                margin-top: 24px;
                height: 128px;
                background: #F5F5F5;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 32px;
                font-weight: bold;

                .checkbox {
                    width: 32px;
                    height: 32px;
                    border: 1px solid #DDD;
                    border-radius: 50%;
                    margin-right: 8px;
                }
            }

            .city-active {
                border: 4px solid #242831;

                .checkbox {
                    width: 48px;
                    height: 48px;
                    background: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/spring2023/check.png);
                    background-size: 100% 100%;
                    border: none;
                    margin: 0;
                }
            }
        }

        .next {
            line-height: 96px;
            background: #FFDE00;
            border-radius: 8px;
            margin-top: 64px;
            font-weight: bold;
            text-align: center;
        }
    }

    .popsure {
        width: 590px;
        border-radius: 12px;
        padding: 50px 40px;

        .sure-title {
            color: #242831;
            font-size: 36px;
            font-weight: bold;
            text-align: center;
        }

        .sure-content {
            color: #3C454E;
            font-size: 24px;
            line-height: 1.6;
            margin: 36px 0 48px;
            text-align: center;

            span {
                color: #F03C18;
            }
        }

        .sure-sub {
            border-radius: 8px;
            background: #FFDE00;
            color: #000;
            font-weight: bold;
            font-size: 32px;
            line-height: 96px;
            text-align: center;
        }
    }

    @bei: 1.5px;

    .canvascss {
        position: fixed;
        bottom: -200%;
        // top: 0;
        width: @bei*654;
        height: @bei*1120;
        margin: 0 auto;
        box-sizing: border-box;

        .canvascss_bg {
            width: 100%;
            height: 100%;
        }


        .canvascss_left {
            position: absolute;
            left: @bei*32;
            bottom: @bei*36;
            z-index: 1;
            width: @bei*100;
            height: @bei*100;
            border-radius: 50%;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .canvascss_name {
            position: absolute;
            left: @bei*162;
            bottom: @bei*112;
            z-index: 1;
            color: #242831;
            font-weight: bold;
            font-size: @bei*28;
            width: @bei*252;
        }

        .canvascss_right {
            position: absolute;
            right: @bei*34;
            bottom: @bei*24;
            width: @bei*140;
            height: @bei*140;
            border-radius: @bei*14;
            overflow: hidden;
            background: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/spring2023/border.png);
            background-size: 100% 100%;
            padding: @bei*18;

            img {
                width: @bei*104;
                height: @bei*104;
            }
        }

    }

    /deep/ .canvas-box {
        padding-left: 40px;
        padding-right: 40px;
        box-sizing: border-box;

        .create-img {
            width: 100% !important;
        }
    }
}</style>
